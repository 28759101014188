.appbar-style {
  background: black;
}

/* Used for text instead of logo on header */
/* Might come in handy sometime */
/* .appbar-link {
  color: white;
  text-decoration: none;
  font-family: "DG One 2y";
  font-size: 1.5rem;
} */

.appbar-link {
  width: 60%;
  float: left;
}

.appbar-about {
  color: white;
  text-decoration: none;
  font-family: "CORDATA";
  float: right;
  font-size: 0.6rem;
  margin-left: 5px;
  margin-right: 5px;
  width: 37%;
  text-align: center;
}

.header-logo {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .header-logo {
    width: 60%;
    height: auto;
  }

  .toolbar-style {
    margin-left: 10%;
    margin-right: 10%;
  }

  .appbar-about {
  color: white;
  text-decoration: none;
  font-family: "CORDATA";
  float: right;
  font-size: 0.8rem;
  margin-left: 5px;
  margin-right: 5px;
  width: 14rem;
  text-align: center;
}
}

@media only screen and (min-width: 1024px) {
  .appbar-about {
    color: white;
    text-decoration: none;
    font-family: "CORDATA";
    float: right;
    font-size: 1.2rem;
    padding: 10px;
  }

  .appbar-about:hover {
    color: #af7d5e;
  }

  .header-logo {
    width: 16rem;
    height: auto;
  }

  .toolbar-style {
    margin-left: 15%;
    margin-right: 15%;
  }
}
