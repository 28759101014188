body {
  margin: 0;
  font-family: "myriad-pro", "Helvetica Neue", Helvetica, Arial, sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1 !important;
}


/* Enable transition when changing theme */
body {
    transition: background-color 0.25s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
	font-family: "IBM DOS";
	src: local("IBM_DOS"),
		url("./fonts/IBM_DOS.woff") format("woff");
}

@font-face {
	font-family: "DG One 2y";
	src: local("DgOne2y"),
		url("./fonts/DG_ONE_2y.woff") format("woff");
}

@font-face {
	font-family: "Bebas Nue Pro";
	src: local("BebasNuePro"),
		url("./fonts/bebas_nue_pro_book.otf") format("otf");
}

@font-face {
	font-family: "Bebas Nue Pro Bold";
	src: local("BebasNuePro"),
		url("./fonts/bebeas_nue_pro_bold.otf") format("otf");
}

@font-face {
	font-family: "IBM 30r0";
	src: local("IBM_30r0"),
		url("./fonts/IBM_30r0-2x.woff") format("woff");
}

@font-face {
	font-family: "IBM EGA 8x8";
	src: local("IBM_EGA_8x8"),
		url("./fonts/IBM_EGA_8x8.woff") format("woff");
}

@font-face {
	font-family: "IBM BIOS";
	src: local("IBM_BIOS"),
		url("./fonts/IBM_BIOS_PLUS.woff") format("woff");
}

@font-face {
	font-family: "IBM CGA";
	src: local("IBM_CGA"),
		url("./fonts/IBM_CGA.woff") format("woff");
}

@font-face {
	font-family: "IBM EGA";
	src: local("IBM_EGA_814"),
		url("./fonts/IBM_EGA_8x14.woff") format("woff");
}

@font-face {
	font-family: "CORDATA";
	src: local("CORDATA_PPC"),
		url("./fonts/CORDATA_PPC.woff") format("woff");
}

/* .debug-class {
  border-width: 5px;
  border: solid;
  border-color: red;
}

.debug-class1 {
  border: solid;
  border-width: 5px;
  border-color: green;
} */
